<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    max-height-form="65vh"
    :submit-title="index >= 0 ? 'Edit' : 'Add'"
    :title="index >= 0 ? 'Edit Department' : 'Add Department'"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="handleSubmit"
  >
    <div class="mb-4 bold">
      <gl-input
        v-model="model.departmentName"
        class="mb-3"
        :height="38"
        label="Department name"
        placeholder="Add department name"
      />

      <InputWidthSelectIncremetn
        input-name="Social Network link"
        :main-data="model.departmentLinks"
        :options="socialIconsList"
        @addLinkInput="addLinkInputDepartment"
        @removeLinkInput="
          i => {
            removeLinkInputDepartment(i)
          }
        "
      />
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input.vue'
import InputWidthSelectIncremetn from '@/pages/entity/components/InputWidthSelectIncremetn'

// Utils
import { socialIconsList } from '@/pages/entity/models/options-list'

export default {
  components: {
    GlInput,
    GlModal,
    InputWidthSelectIncremetn,
  },
  inheritAttrs: false,
  props: {
    countriesList: {
      type: Array,
      default: () => [],
    },
    modelData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      model: this.modelData,
    }
  },
  computed: {
    socialIconsList() {
      return socialIconsList
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('confirm', { ...this.model }, this.index)
    },
    addLinkInputDepartment() {
      this.model.departmentLinks.push({
        key: '',
        link: '',
      })
    },
    removeLinkInputDepartment(index) {
      this.model.departmentLinks.splice(index, 1)
    },
  },
}
</script>