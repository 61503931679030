
export default {
    data() {
      return {
        resolveModalPromise: null,
        rejectModalPromise: null,
      }
    },
    methods: {
      confirmModalPromice() {
        return new Promise((resolve, reject) => {
          this.resolveModalPromise = e => {
            resolve(e)
          }
          this.rejectModalPromise = e => {
            reject(e)
          }
        })
      },
    }
}
