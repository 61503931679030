<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    max-height-form="65vh"
    :submit-title="index >= 0 ? 'Edit' : 'Add'"
    :title="
      index >= 0 ? 'Edit Regulatory Compliance' : 'Add Regulatory Compliance'
    "
    v-on="$listeners"
    @close="$emit('close')"
    @submit="handleSubmit"
  >
    <div class="mb-4 bold">
      <GlSelect
        class="mb-4"
        :clearable="true"
        label="Country"
        label-select="name"
        :options="countriesList"
        placeholder="Choose country"
        rules=""
        searchable
        :value="
          model.country
            ? {
              name: model.country,
              icon: model.icon,
            }
            : []
        "
        @change="
          data => {
            model.country = data && data.name
            model.icon = data && data.icon
          }
        "
      >
        <template #selected-slot="{ data }">
          <div class="flex align-center">
            <GlImageViewer
              v-if="data.icon"
              class="mr-1 flag-icon-small"
              :src="data.icon"
            />
            <div>{{ data.name }}</div>
          </div>
        </template>
      </GlSelect>
      <gl-input
        v-model="model.localAuthority"
        class="mb-3"
        :height="38"
        label="Local Authority"
        placeholder="Add local authority"
      />
      <gl-input
        v-model="model.licenseNumber"
        class="mb-3"
        :height="38"
        label="License Number"
        placeholder="Add license number"
      />
      <gl-input
        v-model="model.licenseType"
        class="mb-3"
        :height="38"
        label="License Type"
        placeholder="Add license type"
      />
      <gl-input
        v-model="model.licenseLink"
        class="mb-3"
        :height="38"
        label="License Link"
        placeholder="Add license link"
      />
      <gl-input
        v-model="model.registeredName"
        class="mb-3"
        :height="38"
        label="Registered Name"
        placeholder="Add name"
      />
      <div class="gl-input__label">
        Start Date
      </div>
      <date-picker
        ref="datePicker"
        v-model="timestampDataFrom"
        class="sankey__calendar"
        confirm
        confirm-text="Apply"
        format="YYYY-MM-DD"
        placeholder="Select start date"
        :value="timestampDataFrom"
        @clear="timestampDataFrom = null"
      >
        <template v-slot:footer>
          <button
            class="mx-btn mx-btn-text cancel-date-range mr-2"
            @click="handleDateClose"
          >
            Cancel
          </button>
        </template>
      </date-picker>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input.vue'
import GlSelect from '@/components/gl-select.vue'
import GlImageViewer from '@/components/gl-image-viewer.vue'

import moment from 'moment'

export default {
  components: {
    GlImageViewer,
    GlSelect,
    GlInput,
    GlModal,
  },
  inheritAttrs: false,
  props: {
    countriesList: {
      type: Array,
      default: () => [],
    },
    modelData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      model: this.modelData,
      timestampDataFrom: this.modelData.dates.from
        ? new Date(
            String(
              moment
                .unix(this.modelData.dates.from / 1000)
                .format('YYYY-MM-DD'),
            ),
          )
        : '',
    }
  },
  methods: {
    handleSubmit() {
      this.$emit(
        'confirm',
        {
          ...this.model,
          dates: { from: new Date(this.timestampDataFrom).getTime() },
        },
        this.index,
      )
    },
    handleDateClose() {
      this.$refs.datePicker.closePopup()
    },
  },
}
</script>
