<template>
  <div>
    <div
      v-for="(item, i) in mainData"
      :key="i"
      class="flex align-end gap-3 input-with-increment-item"
    >
      <GlSelect
        v-if="options && options.length"
        class="icon-select"
        :clearable="true"
        :label="i === 0 ? 'Icon' : ''"
        label-select="name"
        :options="options"
        placeholder="Icon"
        :searchable="false"
        :value="item.key"
        @change="
          e => {
            item.key = e && e.name
          }
        "
      >
        <template #selected-slot="{ data }">
          <GlImageViewer
            v-if="getValueByKey(options, data.name, 'key')"
            class="mr-1 icon-small"
            :src="getValueByKey(options, data.name, 'key')"
          />
        </template>
        <template #option-slot="{ data }">
          <GlImageViewer
            v-if="getValueByKey(options, data.name, 'key')"
            class="mr-1 icon-small"
            :src="getValueByKey(options, data.name, 'key')"
          />
        </template>
      </GlSelect>
      <gl-input
        v-model.trim="item.link"
        class="fullwidth"
        :height="38"
        is-block-error-style
        :label="i === 0 ? inputName : ''"
        :name="inputName"
        placeholder="Add link"
        style="margin-right: -8px;"
      />
      <gl-icon
        v-if="i === mainData.length - 1"
        class="mb-2 pointer"
        :height="24"
        link
        name="add"
        style="min-width: 24px"
        :width="24"
        @click="$emit('addLinkInput')"
      />
      <gl-icon
        v-else
        class="mb-2 pointer"
        :height="24"
        link
        name="clear-large-dark"
        style="min-width: 24px"
        :width="24"
        @click="$emit('removeLinkInput', i)"
      />
    </div>
  </div>
</template>

<script>
// Components
import GlInput from '@/components/gl-input.vue'
import GlSelect from '@/components/gl-select.vue'
import GlIcon from '@/components/gl-icon'
import GlImageViewer from '@/components/gl-image-viewer'
//utils
import { getValueByKey } from '@/utils/helpers'

export default {
  name: 'InputWidthSelectIncremetn',
  components: {
    GlInput,
    GlSelect,
    GlIcon,
    GlImageViewer
  },
  props: {
    mainData: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    inputName: {
      type: String,
      default: '',
    },
  },
  methods: {
    getValueByKey,
  },
}
</script>

<style scoped>
  .gl-select__wrapper,
  .gl-input__wrapper {
    margin-bottom: 0 !important;
  }
  .gl-select__wrapper + .gl-input__wrapper::v-deep .gl-input__input {
    height: 32px !important;
  }
  .gl-select__wrapper::v-deep .vs__clear {
    display: none !important;
  }
  .input-with-increment-item + .input-with-increment-item {
    margin-top: 16px;
  }
  
  .input-with-increment-item::v-deep .mb-2.gl-icon {
    margin-bottom: 4px !important;
  }
  .input-with-increment-item::v-deep .v-select {
    min-width: 68px;
  }
  .input-with-increment-item::v-deep .vs__search {
    margin: 0;
    padding: 0;
  }
  .input-with-increment-item::v-deep .vs__dropdown-toggle {
    padding-bottom: 0;
    padding-left: 6px;
  }
  .input-with-increment-item::v-deep .vs__selected-options {
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
  }
  .input-with-increment-item::v-deep .vs__selected {
    margin: 0;
    padding: 0;
  }
  .input-with-increment-item::v-deep .vs--single.vs--open .vs__selected {
    position: relative;
  }
  .input-with-increment-item::v-deep .vs__actions {
    padding-top: 0;
  }
</style>