var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gl-modal',_vm._g(_vm._b({attrs:{"full-buttons":"","max-height-form":"65vh","submit-title":_vm.index >= 0 ? 'Edit' : 'Add',"title":_vm.index >= 0 ? 'Edit Bank' : 'Add Bank'},on:{"close":function($event){return _vm.$emit('close')},"submit":_vm.handleSubmit}},'gl-modal',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"mb-4 bold"},[_c('GlSelect',{staticClass:"mb-4",attrs:{"clearable":true,"label":"Country","label-select":"name","options":_vm.countriesList,"placeholder":"Choose country","searchable":"","value":_vm.model.country
          ? {
            name: _vm.model.country,
            icon: _vm.model.icon,
          }
          : []},on:{"change":data => {
          _vm.model.country = data && data.name
          _vm.model.icon = data && data.icon
        }},scopedSlots:_vm._u([{key:"selected-slot",fn:function({ data }){return [_c('div',{staticClass:"flex align-center"},[(data.icon)?_c('GlImageViewer',{staticClass:"mr-1 flag-icon-small",attrs:{"src":data.icon}}):_vm._e(),_c('div',[_vm._v(_vm._s(data.name))])],1)]}}])}),_c('gl-input',{staticClass:"mb-3",attrs:{"height":38,"label":"Bank Name","placeholder":"Add bank name"},model:{value:(_vm.model.bankName),callback:function ($$v) {_vm.$set(_vm.model, "bankName", $$v)},expression:"model.bankName"}}),_c('gl-input',{staticClass:"mb-3",attrs:{"height":38,"label":"Holder","placeholder":"Add holder name"},model:{value:(_vm.model.holder),callback:function ($$v) {_vm.$set(_vm.model, "holder", $$v)},expression:"model.holder"}}),_c('gl-input',{staticClass:"mb-3",attrs:{"height":38,"label":"Account number","placeholder":"Add account number"},model:{value:(_vm.model.accountNumber),callback:function ($$v) {_vm.$set(_vm.model, "accountNumber", $$v)},expression:"model.accountNumber"}}),_c('gl-input',{staticClass:"mb-3",attrs:{"height":38,"label":"Bank BIC/SWIFT","placeholder":"Add BIC/SWIFT code"},model:{value:(_vm.model.BIC),callback:function ($$v) {_vm.$set(_vm.model, "BIC", $$v)},expression:"model.BIC"}}),_c('gl-input',{staticClass:"mb-3",attrs:{"height":38,"label":"IBAN","placeholder":"Add IBAN code"},model:{value:(_vm.model.IBAN),callback:function ($$v) {_vm.$set(_vm.model, "IBAN", $$v)},expression:"model.IBAN"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }