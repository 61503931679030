<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    max-height-form="65vh"
    :submit-title="index >= 0 ? 'Edit' : 'Add'"
    :title="
      index >= 0 ? 'Edit Payment Service' : 'Add Payment Service'
    "
    v-on="$listeners"
    @close="$emit('close')"
    @submit="handleSubmit"
  >
    <div class="mb-4 bold">
      <div
        class="flex align-end mb-4"
      >
        <gl-input
          v-model="model.systemName"
          class="mr-2 fullwidth"
          :height="38"
          label="Service Name"
          placeholder="Add service Name"
        />
        <GlSelect
          class="icon-select mr-2"
          :clearable="true"
          label="Logo"
          label-select="name"
          :options="paymentIconsList"
          placeholder="Logo"
          searchable
          :value="model.logo"
          @change="
            e => {
              model.logo = e && e.name
            }
          "
        >
          <template #selected-slot="{ data }">
            <GlImageViewer
              v-if="getValueByKey(paymentIconsList, data.name, 'key')"
              class="mr-1 icon-small"
              :src="getValueByKey(paymentIconsList, data.name, 'key')"
            />
          </template>
          <template #option-slot="{ data }">
            <GlImageViewer
              v-if="getValueByKey(paymentIconsList, data.name, 'key')"
              class="mr-1 icon-small"
              :src="getValueByKey(paymentIconsList, data.name, 'key')"
            />
          </template>
        </GlSelect>
      </div>

      <gl-input
        v-model="model.paymentType"
        class="mb-3"
        :height="38"
        label="Payment Type"
        placeholder="Add Payment Type"
      />
      <gl-input
        v-model="model.paymentMethods"
        class="mb-3"
        :height="38"
        label="Payment methods"
        placeholder="Add payment methods"
      />
      
      <gl-input
        v-model="model.website"
        class="mb-3"
        :height="38"
        label="Website"
        placeholder="Add website"
      />
      <GlSelect
        class="mb-4 tagged gl-modal__row mr-1"
        :clearable="true"
        label="Domiciled Country"
        label-select="name"
        :multiple="true"
        :options="countriesList"
        placeholder="Choose countries"
        searchable
        :value="model.registrationGeography.value"
        @change="
          e => (model.registrationGeography.value = e)
        "
      >
        <template #selected-slot="{ data }">
          <div class="flex align-center">
            <GlImageViewer
              v-if="data.icon"
              class="mr-1 flag-icon-small"
              :src="data.icon"
            />
            <div>{{ data.name }}</div>
          </div>
        </template>
      </GlSelect>
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input.vue'
import GlSelect from '@/components/gl-select.vue'
import GlImageViewer from '@/components/gl-image-viewer.vue'
import GlIcon from '@/components/gl-icon'
//models
import { paymentIconsList } from '@/pages/entity/models/options-list'
//utils
import { getValueByKey } from '@/utils/helpers'

export default {
  components: {
    GlImageViewer,
    GlSelect,
    GlInput,
    GlModal,
    GlIcon
  },
  inheritAttrs: false,
  props: {
    countriesList: {
      type: Array,
      default: () => [],
    },
    modelData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      model: this.modelData,
    }
  },
  computed: {
    paymentIconsList() {
      return paymentIconsList
    },
  },
  methods: {
    getValueByKey,
    handleSubmit() {
      this.$emit('confirm', { ...this.model }, this.index)
    },
  },
}
</script>
