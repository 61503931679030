<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    max-height-form="65vh"
    :submit-title="index >= 0 ? 'Edit' : 'Add'"
    :title="index >= 0 ? 'Edit Bank' : 'Add Bank'"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="handleSubmit"
  >
    <div class="mb-4 bold">
      <GlSelect
        class="mb-4"
        :clearable="true"
        label="Country"
        label-select="name"
        :options="countriesList"
        placeholder="Choose country"
        searchable
        :value="
          model.country
            ? {
              name: model.country,
              icon: model.icon,
            }
            : []
        "
        @change="
          data => {
            model.country = data && data.name
            model.icon = data && data.icon
          }
        "
      >
        <template #selected-slot="{ data }">
          <div class="flex align-center">
            <GlImageViewer
              v-if="data.icon"
              class="mr-1 flag-icon-small"
              :src="data.icon"
            />
            <div>{{ data.name }}</div>
          </div>
        </template>
      </GlSelect>
      <gl-input
        v-model="model.bankName"
        class="mb-3"
        :height="38"
        label="Bank Name"
        placeholder="Add bank name"
      />
      <gl-input
        v-model="model.holder"
        class="mb-3"
        :height="38"
        label="Holder"
        placeholder="Add holder name"
      />
      <gl-input
        v-model="model.accountNumber"
        class="mb-3"
        :height="38"
        label="Account number"
        placeholder="Add account number"
      />
      <gl-input
        v-model="model.BIC"
        class="mb-3"
        :height="38"
        label="Bank BIC/SWIFT"
        placeholder="Add BIC/SWIFT code"
      />
      <gl-input
        v-model="model.IBAN"
        class="mb-3"
        :height="38"
        label="IBAN"
        placeholder="Add IBAN code"
      />
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input.vue'
import GlSelect from '@/components/gl-select.vue'
import GlImageViewer from '@/components/gl-image-viewer.vue'

export default {
  components: {
    GlImageViewer,
    GlSelect,
    GlInput,
    GlModal,
  },
  inheritAttrs: false,
  props: {
    countriesList: {
      type: Array,
      default: () => [],
    },
    modelData: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      model: this.modelData,
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('confirm', { ...this.model }, this.index)
    },
  },
}
</script>
