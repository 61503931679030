<template>
  <div class="entity-form">
    <div class="entity-form__header-stepper">
      <div class="entity-form__header-stepper-wrap">
        <div
          v-for="(item, i) in steps"
          :key="i"
          class="entity-form__header-stepper-item"
          :class="{ 'entity-form__header-stepper-item--active': currentStep >= i }"
          @click="handleActiveStep(i)"
        >
          <div class="entity-form__header-stepper-item-mark" />
          <div class="entity-form__header-stepper-item-text">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <ValidationObserver
      ref="entityForm"
      v-slot="{ handleSubmit, errors }"
      slim
    >
      <form @submit.prevent="handleSubmit(onSubmit, errors)">
        <div
          v-show="loading"
          class="flex align-center justify-center full-height"
        >
          <gl-loader />
        </div>
        <div v-show="!loading">
          <div
            v-show="activeStep === 'Overview'"
            class="entity-form__main-form"
          >
            <div class="entity-form__main-form-left">
              <div class="entity-form__main-form-header">
                Summary
              </div>
              <gl-input
                v-model="formData.name.value"
                class="entity-form__main-form-block"
                :height="35"
                is-block-error-style
                label="Entity Name"
                name="Entity Name"
                placeholder="Entity name"
                rules="required"
              />
              <gl-input
                v-model="formData.data.legalName.value"
                class="entity-form__main-form-block"
                :height="35"
                is-block-error-style
                label="Legal Name"
                name="Legal Name"
                placeholder="Legal Name"
              />
              <gl-input
                v-model="formData.data.parentCompany.value"
                class="entity-form__main-form-block"
                :height="35"
                is-block-error-style
                label="Parent Company / Ownership"
                name="Parent Company / Ownership"
                placeholder="Company name"
              />

              <InputWidthSelectIncremetn
                class="entity-form__without-wrapper"
                input-name="Entity website"
                :main-data="formData.data.website.value"
                @addLinkInput="addWebsiteLinkInput"
                @removeLinkInput="
                  i => {
                    removeWebsiteLinkInput(i)
                  }
                "
              />

              <GlSelect
                class="entity-form__main-form-block"
                :clearable="true"
                label="Domiciled Country"
                label-select="name"
                :options="formData.data.domicliedCountry.items"
                placeholder="Choose country"
                searchable
                :value="
                  formData.data.domicliedCountry.value
                    ? {
                      name: formData.data.domicliedCountry.value,
                      icon: formData.data.domicliedCountry.icon,
                    }
                    : []
                "
                @change="
                  data => {
                    formData.data.domicliedCountry.value = data && data.name
                    formData.data.domicliedCountry.icon = data && data.icon
                  }
                "
              >
                <template #selected-slot="{ data }">
                  <div class="flex align-center">
                    <GlImageViewer
                      v-if="data.icon"
                      class="flag-icon-small"
                      :src="data.icon"
                    />
                    <div>{{ data.name }}</div>
                  </div>
                </template>
              </GlSelect>

              <div class="entity-form__main-form-block entity-form__main-form-block--with-color">
                <gl-input
                  v-model="formData.data.status.value"
                  :height="38"
                  is-block-error-style
                  label="Status"
                  name="Status"
                  placeholder="Status"
                />
                <GlSelect
                  class="icon-select"
                  :clearable="true"
                  label="Color"
                  label-select="key"
                  :options="colorsList"
                  placeholder="Color"
                  :searchable="false"
                  :value="formData.data.status.color"
                  @change="
                    data => {
                      formData.data.status.color = data && data.key
                    }
                  "
                >
                  <template #selected-slot="{ data }">
                    <div
                      class="color-item"
                      :style="{ 'background-color': data.key }"
                    />
                  </template>
                  <template #option-slot="{ data }">
                    <div
                      class="color-item"
                      :style="{ 'background-color': data.key }"
                    />
                  </template>
                </GlSelect>
              </div>

              <GlSelect
                class="entity-form__main-form-block"
                :clearable="true"
                label="Type"
                label-select="name"
                :options="formData.data.type.items"
                placeholder="Choose types"
                rules="required|vSelectMultiple"
                searchable
                :value="formData.data.type.value"
                @change="e => (formData.data.type.value = e)"
              />
              <GlSelect
                class="entity-form__main-form-block tagged gl-modal__row"
                :clearable="true"
                label="Associated Tags"
                label-select="name"
                :multiple="true"
                :options="formData.data.tags.items"
                placeholder="Choose tags"
                searchable
                :value="formData.data.tags.value"
                @change="e => (formData.data.tags.value = e)"
              >
                <template #selected-slot="{ data }">
                  {{ data.name }}
                </template>
              </GlSelect>

              <div class="entity-form__main-form-block">
                <div>
                  <p class="gl-select__label">
                    Description
                  </p>
                </div>
                <div class="entity-form__main-form-block--wswg">
                  <GlEditor
                    v-model="formData.data.description.value"
                    class="entity-quill-editor"
                  />
                </div>
              </div>

              <div class="flex column">
                <span class="label mb-2">Screenshots</span>
                <div class="entity-screensot-wrapper">
                  <div
                    v-for="(item, i) in formData.data.screenshoots.value"
                    :key="i"
                    class="entity-screensot"
                    @click="
                      viewScreenshoot(formData.data.screenshoots.value, i)
                    "
                  >
                    <div
                      v-if="
                        item.icon && item.icon.includes('data:application/pdf;')
                      "
                    >
                      <embed
                        class="pt-4"
                        :src="item.icon"
                        type="application/pdf"
                      >
                      <div class="entity-screensot-full-view-icon link-text">
                        Full view
                      </div>
                    </div>

                    <GlImageViewer
                      v-else
                      :src="item.icon"
                    />
                    <gl-icon
                      class="entity-screensot-clear-icon"
                      :height="14"
                      name="clear"
                      style="min-width: 14px"
                      :width="14"
                      @click.stop="removeScreenshoot(i)"
                    />
                  </div>
                </div>
                <div>
                  <div
                    class="flex flex-items-center mb-4"
                    @click="openFileInput"
                  >
                    <gl-icon
                      class="mr-1"
                      :height="24"
                      link
                      name="add"
                      style="min-width: 24px"
                      :width="24"
                    />
                    <span class="link">Add Screenshots</span>
                  </div>
                  <input
                    ref="imageUploader"
                    multiple="multiple"
                    :style="{ display: 'none' }"
                    type="file"
                    @input="createImage"
                  >
                </div>
              </div>
            </div>
            <div class="entity-form__main-form-right">
              <div class="entity-form__main-form-header">
                Entity Details
              </div>
              <div class="entity-form__main-form-block entity-form__main-form-block--with-color">
                <gl-input
                  v-model="formData.data.isKYC.value"
                  :height="35"
                  is-block-error-style
                  label="KYC"
                  name="KYC"
                  placeholder="KYC"
                />
                <GlSelect
                  :clearable="true"
                  label="Color"
                  label-select="key"
                  :options="colorsList"
                  placeholder="Color"
                  :searchable="false"
                  :value="formData.data.isKYC.color"
                  @change="
                    data => {
                      formData.data.isKYC.color = data && data.key
                    }
                  "
                >
                  <template #selected-slot="{ data }">
                    <div
                      class="color-item"
                      :style="{ 'background-color': data.key }"
                    />
                  </template>
                  <template #option-slot="{ data }">
                    <div
                      class="color-item"
                      :style="{ 'background-color': data.key }"
                    />
                  </template>
                </GlSelect>
              </div>
              <gl-input
                v-model="formData.data.providedServices.value"
                class="entity-form__main-form-block"
                :height="35"
                is-block-error-style
                label="Provided Services"
                name="Provided Services"
                placeholder="Provided Services"
              />

              <GlSelect
                class="entity-form__main-form-block tagged gl-modal__row mr-1"
                :clearable="true"
                label="Primary operational regions"
                label-select="name"
                :multiple="true"
                :options="formData.data.primaryOperationalRegions.items"
                placeholder="Choose countries"
                searchable
                :value="formData.data.primaryOperationalRegions.value"
                @change="
                  e => (formData.data.primaryOperationalRegions.value = e)
                "
              >
                <template #selected-slot="{ data }">
                  <div class="flex align-center">
                    <GlImageViewer
                      v-if="data.icon"
                      class="mr-1 flag-icon-small"
                      :src="data.icon"
                    />
                    <div>{{ data.name }}</div>
                  </div>
                </template>
              </GlSelect>
              <GlSelect
                class="entity-form__main-form-block tagged gl-modal__row mr-1"
                :clearable="true"
                label="Restricted Jurisdictions"
                label-select="name"
                :multiple="true"
                :options="formData.data.restrictedJurisdictions.items"
                placeholder="Choose countries"
                searchable
                :value="formData.data.restrictedJurisdictions.value"
                @change="e => (formData.data.restrictedJurisdictions.value = e)"
              >
                <template #selected-slot="{ data }">
                  <div class="flex align-center">
                    <GlImageViewer
                      v-if="data.icon"
                      class="mr-1 flag-icon-small"
                      :src="data.icon"
                    />
                    <div>{{ data.name }}</div>
                  </div>
                </template>
              </GlSelect>
              <GlSelect
                class="entity-form__main-form-block tagged gl-modal__row mr-1"
                :clearable="true"
                label="Fiat currency trading"
                label-select="name"
                :multiple="false"
                :options="formData.data.isFiatCurrencyTrading.items"
                placeholder="Choose"
                searchable
                :value="formData.data.isFiatCurrencyTrading.value"
                @change="
                  data => {
                    formData.data.isFiatCurrencyTrading.value =
                      data && data.name
                  }
                "
              />

              <gl-input
                v-model="formData.data.officeAddress.value"
                class="entity-form__main-form-block"
                :height="35"
                is-block-error-style
                label="Office address"
                name="Office address"
                placeholder="Add address"
              />

              <GlSelect
                class="entity-form__main-form-block tagged gl-modal__row mr-1"
                :clearable="false"
                label="Languages"
                label-select="name"
                :multiple="true"
                :options="formData.data.languages.items"
                placeholder="Select languages"
                searchable
                :value="formData.data.languages.value"
                @change="e => (formData.data.languages.value = e)"
              >
                <template #selected-slot="{ data }">
                  {{ data.name }}
                </template>
              </GlSelect>

              <GlSelect
                class="entity-form__main-form-block tagged gl-modal__row mr-1"
                :clearable="true"
                label="Privacy coins supported flag"
                label-select="name"
                :multiple="false"
                :options="formData.data.isPrivacyCoinsSupported.items"
                placeholder="Choose"
                searchable
                :value="formData.data.isPrivacyCoinsSupported.value"
                @change="
                  data => {
                    formData.data.isPrivacyCoinsSupported.value =
                      data && data.name
                  }
                "
              />

              <div class="entity-form__main-form-block">
                <InputWidthSelectIncremetn
                  input-name="Social Network link"
                  :main-data="formData.data.socialNetworkLinks.value"
                  :options="socialIconsList"
                  @addLinkInput="addLinkInput"
                  @removeLinkInput="
                    i => {
                      removeLinkInput(i)
                    }
                  "
                />
              </div>
            </div>
          </div>
          <div
            v-show="activeStep === 'Regulatory Compliance'"
            class="entity-form-content-wrapper relative"
          >
            <RegulatoryComplianceModal
              v-if="openRegulatoryComplianceModal"
              v-model="openRegulatoryComplianceModal"
              :countries-list="countriesList"
              :index="selectedRegulatoryCompliance.index"
              :model-data="selectedRegulatoryCompliance.data"
              @close="openRegulatoryComplianceModal = false"
              @confirm="addRegulatory"
            />
            <div class="pp-entity__table-wrap">
              <EntityRegulatoryComplianceTable
                v-if="formData.data.regulatoryCompliance.length"
                class="regulatory-compliance-table regulatory-compliance-table--form"
                deletable
                :no-pagination="true"
                :table-data="formData.data.regulatoryCompliance"
                @delete="deleteRegulatoryCompliance"
                @edit="openRegulatoryComplianceModalOpen"
              />
            </div>
            <div
              class="flex column align-center"
              :class="{
                'entity-form-content-wrapper justify-center':
                  !formData.data.regulatoryCompliance.length,
              }"
            >
              <div
                v-if="!formData.data.regulatoryCompliance.length"
                class="text-center mt-4 mb-4 bold fs-14"
              >
                You currently have no regulation information. <br>
                Click below to add new details.
              </div>
              <div
                class="flex justify-center accent-text-link pointer align-center fs-14 mt-1 mb-4"
                @click="openRegulatoryComplianceModalOpen"
              >
                <gl-icon
                  class="mr-1"
                  :height="24"
                  link
                  name="add"
                  style="min-width: 24px"
                  :width="24"
                />
                Add Regulatory Compliance
              </div>
            </div>
          </div>
          <div
            v-show="activeStep === 'Banks'"
            class="entity-form-content-wrapper"
          >
            <BanksModal
              v-if="openBanksModal"
              v-model="openBanksModal"
              :countries-list="countriesList"
              :index="selectedBank.index"
              :model-data="selectedBank.data"
              @close="openBanksModal = false"
              @confirm="addBank"
            />
            <div class="pp-entity__table-wrap">
              <EntityBanksTable
                v-if="formData.data.banks.length"
                class="banks-table banks-table--form"
                deletable
                :no-pagination="true"
                :table-data="formData.data.banks"
                @delete="deleteBank"
                @edit="openBanksModalOpen"
              />
            </div>
            <div
              class="flex column align-center"
              :class="{
                'entity-form-content-wrapper justify-center':
                  !formData.data.banks.length,
              }"
            >
              <div
                v-if="!formData.data.banks.length"
                class="text-center mt-4 mb-4 bold fs-14"
              >
                You currently have no banks. <br>
                Click below to add a new one.
              </div>
              <div
                class="flex justify-center align-center accent-text-link pointer fs-14 mt-1 mb-4"
                @click="openBanksModalOpen"
              >
                <gl-icon
                  class="mr-1"
                  :height="24"
                  link
                  name="add"
                  style="min-width: 24px"
                  :width="24"
                />
                Add Bank
              </div>
            </div>
          </div>
          <div
            v-show="activeStep === 'Payment Service'"
            class="entity-form-content-wrapper relative"
          >
            <PaymentSystemsModal
              v-if="openPaymentSystemsModal"
              v-model="openPaymentSystemsModal"
              :countries-list="countriesList"
              :index="selectedPaymentSystems.index"
              :model-data="selectedPaymentSystems.data"
              @close="openPaymentSystemsModal = false"
              @confirm="addPaymentSystems"
            />
            <div class="pp-entity__table-wrap">
              <EntityPaymentSystemsTable
                v-if="formData.data.paymentSystems.length"
                class="payments-systems-table payments-systems-table--form"
                deletable
                :no-pagination="true"
                :table-data="formData.data.paymentSystems"
                @delete="deletePaymentSystems"
                @edit="openPaymentSystemsModalOpen"
              />
            </div>
            <div
              class="flex column align-center"
              :class="{
                'entity-form-content-wrapper justify-center':
                  !formData.data.paymentSystems.length,
              }"
            >
              <div
                v-if="!formData.data.paymentSystems.length"
                class="text-center mt-4 mb-4 bold fs-14"
              >
                You currently have no payment service. <br>
                Click below to add new details.
              </div>
              <div
                class="flex justify-center accent-text-link align-center pointer fs-14 mt-1 mb-4"
                @click="openPaymentSystemsModalOpen"
              >
                <gl-icon
                  class="mr-1"
                  :height="24"
                  link
                  name="add"
                  style="min-width: 24px"
                  :width="24"
                />
                Add Payment Service
              </div>
            </div>
          </div>
          <div
            v-show="activeStep === 'Contacts'"
            class="entity-form-content-wrapper entity-form-content-wrapper--form"
          >
            <div class="pt-4">
              <div class="entity-form__main-form-header">
                People
              </div>
              <ContactsModal
                v-if="openContactsModal"
                v-model="openContactsModal"
                :countries-list="countriesList"
                :index="selectedContact.index"
                :model-data="selectedContact.data"
                @close="openContactsModal = false"
                @confirm="addContact"
              />
              <div class="pp-entity__table-wrap">
                <EntityContactsTable
                  v-if="formData.data.contacts.length"
                  class="contacts-people-table contacts-people-table--form"
                  deletable
                  :no-pagination="true"
                  :table-data="formData.data.contacts"
                  @delete="deleteContact"
                  @edit="openContactsModalOpen"
                />
              </div>
              <div
                class="flex column align-center"
                :class="{
                  'entity-form-content-contact-departments-wrapper justify-center':
                    !formData.data.contacts.length,
                }"
              >
                <div
                  v-if="!formData.data.contacts.length"
                  class="text-center mt-4 mb-4 bold fs-14"
                >
                  You currently have no contacts. <br>
                  Click below to add a new one.
                </div>
                <div
                  class="flex justify-center align-center accent-text-link pointer fs-14 mt-1 mb-4"
                  @click="openContactsModalOpen"
                >
                  <gl-icon
                    class="mr-1"
                    :height="24"
                    link
                    name="add"
                    style="min-width: 24px"
                    :width="24"
                  />
                  Add Contact
                </div>
              </div>
            </div>
            <div>
              <div class="entity-form__main-form-header">
                Departments
              </div>
              <ContactsDepartmentsModal
                v-if="openContactsDepartmentsModal"
                v-model="openContactsDepartmentsModal"
                :countries-list="countriesList"
                :index="selectedContactDepartments.index"
                :model-data="selectedContactDepartments.data"
                @close="openContactsDepartmentsModal = false"
                @confirm="addContactsDepartment"
              />
              <div class="pp-entity__table-wrap">
                <EntityContactsDepartmentsTable
                  v-if="formData.data.contactsDepartments.length"
                  class="contacts-departments-table contacts-departments-table--form"
                  deletable
                  :no-pagination="true"
                  :table-data="formData.data.contactsDepartments"
                  @delete="deleteContactDepartment"
                  @edit="openContactsDepartmentsModalOpen"
                />
              </div>
              <div
                class="flex column align-center"
                :class="{
                  'entity-form-content-contact-departments-wrapper justify-center':
                    !formData.data.contactsDepartments.length,
                }"
              >
                <div
                  v-if="!formData.data.contactsDepartments.length"
                  class="text-center mt-4 mb-4 bold fs-14"
                >
                  You currently have no departments. <br>
                  Click below to add a new one.
                </div>
                <div
                  class="flex justify-center align-center accent-text-link pointer fs-14 mt-1 mb-4"
                  @click="openContactsDepartmentsModalOpen"
                >
                  <gl-icon
                    class="mr-1"
                    :height="24"
                    link
                    name="add"
                    style="min-width: 24px"
                    :width="24"
                  />
                  Add Department
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <div class="entity-footer-wrapper flex space-between align-center gap-3">
      <div
        v-if="$route.name === 'create-entity-management'"
        class="pointer entity-form-reset-button"
        @click="clearFormData"
      >
        <gl-icon
          height="20"
          name="reset-all-red"
          width="20"
        />
        <span>Reset all data</span>
      </div>
      <div v-else />
      <div class="flex gap-3">
        <GlButton
          v-show="currentStep !== 0"
          class="m-fullwidth entity-form-prev-button"
          :icon-only="isMobile"
          pale-light
          :style="[{ height: '32px' }]"
          title="Previous Step"
          @click="prevStep"
        >
          <template #beforeTitle>
            <gl-icon
              height="24"
              name="blue-left"
              width="24"
            />
          </template>
        </GlButton>
        <GlButton
          v-show="currentStep !== steps.length - 1"
          class="m-fullwidth entity-form-next-button"
          :icon-only="isMobile"
          pale-light
          :style="[{ height: '32px' }]"
          title="Next Step"
          @click="nextStep"
        >
          <template #afterTitle>
            <gl-icon
              height="24"
              name="blue-right"
              width="24"
            />
          </template>
        </GlButton>
      </div>
    </div>
    <GlConfirmModal
      v-show="showConfirmModal"
      v-model="showConfirmModal"
      :title="confirmModalTitle"
      @close="showConfirmModal = false"
      @confirm="resolveModalPromise(true)"
    >
      <template #main-slot>
        <div ref="confirmModalMainContent" />
      </template>
    </GlConfirmModal>
  </div>
</template>

<script>
//Components
import GlSelect from '@/components/gl-select'
import GlInput from '@/components/gl-input'
import GlButton from '@/components/gl-button'
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
import GlImageViewer from '@/components/gl-image-viewer'
import RegulatoryComplianceModal from '@/pages/entity/modals/RegulatoryComplianceModal.vue'
import BanksModal from '@/pages/entity/modals/BanksModal.vue'
import ContactsModal from '@/pages/entity/modals/ContactsModal.vue'
import ContactsDepartmentsModal from '@/pages/entity/modals/ContactsDepartmentsModal.vue'
import PaymentSystemsModal from '@/pages/entity/modals/PaymentSystemsModal.vue'
import EntityRegulatoryComplianceTable from '@/pages/entity/components/EntityRegulatoryComplianceTable.vue'
import EntityPaymentSystemsTable from '@/pages/entity/components/EntityPaymentSystemsTable.vue'
import EntityBanksTable from '@/pages/entity/components/EntityBanksTable.vue'
import EntityContactsTable from '@/pages/entity/components/EntityContactsTable.vue'
import EntityContactsDepartmentsTable from '@/pages/entity/components/EntityContactsDepartmentsTable.vue'
import InputWidthSelectIncremetn from '@/pages/entity/components/InputWidthSelectIncremetn'
import GlConfirmModal from '@/components/gl-confirm-modal'
import GlEditor from '@/components/gl-editor'
// Libs
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
// Vuex
import { mapActions } from 'vuex'
// Models
import {
  entityFields,
  regulatoryComplianceFields,
  banksFields,
  contactsFields,
  contactsDepartmentsFields,
  paymentSystemsFields,
} from '../models/entity-fields'
import { countriesForRestrictedJurisdictions } from '@/pages/entity/models/countries-list'
// Utils
import { formatDate } from '@/utils/format-date'
import { socialIconsList, colorsList } from '@/pages/entity/models/options-list'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import confirmModalMixin from '@/assets/mixins/confirmModalMixin'

export default {
  components: {
    RegulatoryComplianceModal,
    BanksModal,
    ContactsModal,
    ContactsDepartmentsModal,
    ValidationObserver,
    GlInput,
    GlSelect,
    GlButton,
    GlLoader,
    GlIcon,
    GlImageViewer,
    EntityContactsTable,
    EntityContactsDepartmentsTable,
    EntityBanksTable,
    EntityRegulatoryComplianceTable,
    EntityPaymentSystemsTable,
    GlConfirmModal,
    PaymentSystemsModal,
    InputWidthSelectIncremetn,
    GlEditor,
  },
  mixins: [confirmModalMixin, deviceWidthMixin],
  props: {
    languagesList: {
      type: Array,
      default: () => [],
    },
    countriesList: {
      type: Array,
      default: () => [],
    },
    typesList: {
      type: Array,
      default: () => [],
    },
    tagsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {},
      currentStep: 0,
      steps: [
        'Overview',
        'Regulatory Compliance',
        'Banks',
        'Payment Service',
        'Contacts',
      ],
      loading: false,
      openRegulatoryComplianceModal: false,
      showConfirmModal: false,
      confirmModalTitle: '',
      openBanksModal: false,
      openContactsModal: false,
      openContactsDepartmentsModal: false,
      openPaymentSystemsModal: false,
      selectedRegulatoryCompliance: {
        data: regulatoryComplianceFields(),
        index: -1,
      },
      selectedBank: {
        data: banksFields(),
        index: -1,
      },
      selectedContact: {
        data: contactsFields(),
        index: -1,
      },
      selectedContactDepartments: {
        data: contactsDepartmentsFields(),
        index: -1,
      },
      selectedPaymentSystems: {
        data: paymentSystemsFields(),
        index: -1,
      },
    }
  },
  computed: {
    activeStep() {
      return this.steps[this.currentStep]
    },
    socialIconsList() {
      return socialIconsList
    },
    colorsList() {
      return colorsList
    },
    countriesForRestrictedJurisdictions() {
      return countriesForRestrictedJurisdictions
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.currentStep = 0
        this.setFormDataFields()
        this.setFormDataItems()
        if (val.name === 'edit-entity-management') {
          this.loadEntity()
        }
      },
      immediate: true,
    },
    formData: {
      handler(form) {
        if (this.$route.name === 'create-entity-management') {
          localStorage.setItem(
            'entityFormData',
            JSON.stringify(this.formattedSendData(form)),
          )
        }
      },
      deep: true,
    },
    languagesList: {
      handler(val) {
        this.formData.data.languages.items = val
      },
    },
    countriesList: {
      handler(val) {
        this.formData.data.domicliedCountry.items = val
        this.formData.data.primaryOperationalRegions.items = val
        this.formData.data.restrictedJurisdictions.items = [
          ...val,
          ...this.countriesForRestrictedJurisdictions,
        ]
      },
    },
    typesList: {
      handler(val) {
        this.formData.data.type.items = val
      },
    },
    tagsList: {
      handler(val) {
        this.formData.data.tags.items = val
      },
    },
  },

  beforeRouteLeave(to, from, next) {
    if (
      from.name === 'edit-entity-management' ||
      from.name === 'create-entity-management'
    ) {
      this.showConfirmModal = true
      this.confirmModalTitle = 'Are you sure?'

      this.$nextTick(() => {
        this.$refs.confirmModalMainContent.outerHTML =
          '<p> Do you want leave this page?</p>'
      })
      this.confirmModalPromice().then(e => {
        if (e) {
          this.showConfirmModal = false
          next()
        }
      })
    }
  },

  methods: {
    formatDate,
    ...mapActions('entity', ['editEntity', 'createEntity', 'getEntityById']),
    entityFields,
    goToViewEntity() {
      if (this.$route?.params?.entityId) {
        this.$router.push({
          name: 'view-entity-management',
          params: { entityId: this.$route.params.entityId },
        })
      } else {
        this.$router.push({name: 'entity-management'})
      }
    },
    deleteRegulatoryCompliance(index) {
      this.showConfirmModal = true
      this.confirmModalTitle = 'Delete Regulatory Compliance'
      this.$nextTick(() => {
        this.$refs.confirmModalMainContent.outerHTML =
          '<p>Are you sure you want to delete regulatory compliance?</p>'
      })
      this.confirmModalPromice().then(e => {
        if (e) {
          this.formData.data.regulatoryCompliance.splice(index, 1)
          this.showConfirmModal = false
        }
      })
    },
    deleteBank(index, { bankName }) {
      this.showConfirmModal = true
      this.confirmModalTitle = 'Delete Bank'
      this.$nextTick(() => {
        this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to delete <b>${bankName}</b> bank?</p>`
      })
      this.confirmModalPromice().then(e => {
        if (e) {
          this.formData.data.banks.splice(index, 1)
          this.showConfirmModal = false
        }
      })
    },
    deleteContact(index, { contactName }) {
      this.showConfirmModal = true
      this.confirmModalTitle = 'Delete Contact'
      this.$nextTick(() => {
        this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to delete <b>${contactName}</b> from contacts?</p>`
      })
      this.confirmModalPromice().then(e => {
        if (e) {
          this.formData.data.contacts.splice(index, 1)
          this.showConfirmModal = false
        }
      })
    },
    deleteContactDepartment(index, { departmentName }) {
      this.showConfirmModal = true
      this.confirmModalTitle = 'Delete Department'
      this.$nextTick(() => {
        this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to delete <b>${departmentName}</b> from departments?</p>`
      })
      this.confirmModalPromice().then(e => {
        if (e) {
          this.formData.data.contactsDepartments.splice(index, 1)
          this.showConfirmModal = false
        }
      })
    },
    deletePaymentSystems(index) {
      this.showConfirmModal = true
      this.confirmModalTitle = 'Delete Payment System'
      this.$nextTick(() => {
        this.$refs.confirmModalMainContent.outerHTML =
          '<p>Are you sure you want to delete payment service?</p>'
      })
      this.confirmModalPromice().then(e => {
        if (e) {
          this.formData.data.paymentSystems.splice(index, 1)
          this.showConfirmModal = false
        }
      })
    },
    addRegulatory(item, index) {
      if (index >= 0) {
        this.$set(
          this.formData.data.regulatoryCompliance,
          index,
          _.cloneDeep(item),
        )
      } else {
        this.formData.data.regulatoryCompliance.push(_.cloneDeep(item))
      }
      this.openRegulatoryComplianceModal = false
    },
    addBank(item, index) {
      if (index >= 0) {
        this.$set(this.formData.data.banks, index, _.cloneDeep(item))
      } else {
        this.formData.data.banks.push(_.cloneDeep(item))
      }
      this.openBanksModal = false
    },
    addContact(item, index) {
      if (index >= 0) {
        this.$set(this.formData.data.contacts, index, _.cloneDeep(item))
      } else {
        this.formData.data.contacts.push({ ...item })
      }
      this.openContactsModal = false
    },
    addContactsDepartment(item, index) {
      if (index >= 0) {
        this.$set(
          this.formData.data.contactsDepartments,
          index,
          _.cloneDeep(item),
        )
      } else {
        this.formData.data.contactsDepartments.push({ ...item })
      }
      this.openContactsDepartmentsModal = false
    },
    addPaymentSystems(item, index) {
      if (index >= 0) {
        this.$set(this.formData.data.paymentSystems, index, _.cloneDeep(item))
      } else {
        this.formData.data.paymentSystems.push(_.cloneDeep(item))
      }
      this.openPaymentSystemsModal = false
    },
    openRegulatoryComplianceModalOpen(index) {
      if (index >= 0) {
        this.selectedRegulatoryCompliance.data = _.cloneDeep(
          this.formData.data.regulatoryCompliance[index],
        )
        this.selectedRegulatoryCompliance.index = index
      } else {
        this.selectedRegulatoryCompliance.data = regulatoryComplianceFields()
        this.selectedRegulatoryCompliance.index = -1
      }
      this.openRegulatoryComplianceModal = true
    },
    openBanksModalOpen(index) {
      if (index >= 0) {
        this.selectedBank.data = _.cloneDeep(this.formData.data.banks[index])
        this.selectedBank.index = index
      } else {
        this.selectedBank.data = banksFields()
        this.selectedBank.index = -1
      }
      this.openBanksModal = true
    },
    openContactsModalOpen(index) {
      if (index >= 0) {
        this.selectedContact.data = _.cloneDeep(
          this.formData.data.contacts[index],
        )
        this.selectedContact.index = index
      } else {
        this.selectedContact.data = contactsFields()
        this.selectedContact.index = -1
      }
      this.openContactsModal = true
    },
    openContactsDepartmentsModalOpen(index) {
      if (index >= 0) {
        this.selectedContactDepartments.data = _.cloneDeep(
          this.formData.data.contactsDepartments[index],
        )
        this.selectedContactDepartments.index = index
      } else {
        this.selectedContactDepartments.data = contactsDepartmentsFields()
        this.selectedContactDepartments.index = -1
      }
      this.openContactsDepartmentsModal = true
    },
    openPaymentSystemsModalOpen(index) {
      if (index >= 0) {
        this.selectedPaymentSystems.data = _.cloneDeep(
          this.formData.data.paymentSystems[index],
        )
        this.selectedPaymentSystems.index = index
      } else {
        this.selectedPaymentSystems.data = paymentSystemsFields()
        this.selectedPaymentSystems.index = -1
      }
      this.openPaymentSystemsModal = true
    },
    openFileInput() {
      this.$refs.imageUploader.click()
    },
    async loadEntity() {
      this.loading = true
      await this.getEntityById({
        id: this.$route.params.entityId,
      })
        .then(({ items }) => {
          if (items && items.length) {
            this.formData = this.formattedReceivedData(items[0])
            this.setFormDataItems()
          }
        })
        .catch(e => {
          this.$toasted.global.error({
            message: e?.response?.data?.data?.message || e,
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async onSubmit() {
      const isValid = await this.$refs.entityForm.validate()

      if (isValid) {
        this.$emit('loadingModal', true)
        if (this.$route.name === 'create-entity-management') {
          const formattedData = this.formattedSendData(this.formData)

          this.createEntity(formattedData)
            .then(({ id }) => {
              localStorage.removeItem('entityFormData')
              this.$router.push({
                name: 'view-entity-management',
                params: { entityId: id },
              })
              this.resolveModalPromise(true)
            })
            .catch(e => {
              this.$toasted.global.error({
                message: e?.response?.data?.data?.message || e,
              })
            })
            .finally(() => {
              this.$emit('loadingModal', false)
              this.$emit('closeConfirmPublishModal')
            })
        }

        if (this.$route.name === 'edit-entity-management') {
          const formattedData = this.formattedSendData(this.formData)

          this.editEntity({ ...formattedData, id: this.$route.params.entityId })
            .then(({ id }) => {
              this.$router.push({
                name: 'view-entity-management',
                params: { entityId: id },
              })
              this.resolveModalPromise(true)
            })
            .catch(e => {
              this.$toasted.global.error({
                message: e?.response?.data?.data?.message || e,
              })
            })
            .finally(() => {
              this.$emit('loadingModal', false)
              this.$emit('closeConfirmPublishModal')
            })
        }
      } else {
        this.currentStep = 0
      }
    },
    clearFormData() {
      this.showConfirmModal = true
      this.confirmModalTitle = 'Clear Data?'
      this.$nextTick(() => {
        this.$refs.confirmModalMainContent.outerHTML =
          '<p>Are you sure you want clear Data?</p>'
      })
      this.confirmModalPromice()
        .then(e => {
          if (e) {
            this.formData = this.entityFields()
            this.setFormDataItems()
            this.showConfirmModal = false
            return true
          }
        })
        .then(e => {
          if (e) {
            localStorage.removeItem('entityFormData')
          }
        })
    },
    formattedSendData({ data, name }) {
      const localData = { name, data: {} }
      for (const property in data) {
        if (!Array.isArray(data[property])) {
          localData['data'][property] = {
            value: data[property].value,
            ...(data[property].icon && { icon: data[property].icon }),
            ...(data[property].color && { color: data[property].color }),
          }
        } else {
          localData['data'][property] = data[property]
        }
      }
      return localData
    },
    formattedReceivedData({ data, name }) {
      const localData = { name, data: {} }
      for (const property in this.formData['data']) {
        if (!data[property]) {
          localData['data'][property] = this.formData['data'][property]
        } else if (data[property] && !Array.isArray(data[property])) {
          localData['data'][property] = {
            ...this.formData['data'][property],
            value: data[property].value,
            ...(data[property].icon && { icon: data[property].icon }),
            ...(data[property].color && { color: data[property].color }),
          }
        } else {
          localData['data'][property] = data[property] || []
        }
      }
      return localData
    },

    viewScreenshoot(value, i) {
      this.$emit('showViewImageModal')
      this.$emit('setImageModalData', value, i)
    },
    removeScreenshoot(index) {
      this.formData.data.screenshoots.value.splice(index, 1)
    },
    addLinkInput() {
      this.formData.data.socialNetworkLinks.value.push({
        key: '',
        link: '',
      })
    },
    removeLinkInput(index) {
      this.formData.data.socialNetworkLinks.value.splice(index, 1)
    },
    addWebsiteLinkInput() {
      this.formData.data.website.value.push({
        key: '',
        link: '',
      })
    },
    removeWebsiteLinkInput(index) {
      this.formData.data.website.value.splice(index, 1)
    },
    setFormDataFields() {
      if (
        this.$route.name === 'create-entity-management' &&
        localStorage.getItem('entityFormData')
      ) {
        this.$toasted.global.success({ message: 'Entity data recovered' })
        this.formData = JSON.parse(localStorage.getItem('entityFormData'))
        this.setFormDataItems()
      } else {
        this.formData = this.entityFields()
      }
    },
    setFormDataItems() {
      this.formData.data.type.items = this.typesList
      this.formData.data.restrictedJurisdictions.items = [
        ...this.countriesList,
        ...this.countriesForRestrictedJurisdictions,
      ]
      this.formData.data.primaryOperationalRegions.items = this.countriesList
      this.formData.data.domicliedCountry.items = this.countriesList
      this.formData.data.tags.items = this.tagsList
      this.formData.data.languages.items = this.languagesList
      this.formData.data.socialNetworkLinks = this.formData.data.socialNetworkLinks.value
        ? this.formData.data.socialNetworkLinks
        : { value: [{ key: '', value: '' }] }
      this.formData.data.isFiatCurrencyTrading.items =
        this.entityFields().data.isFiatCurrencyTrading.items
      this.formData.data.isPrivacyCoinsSupported.items =
        this.entityFields().data.isPrivacyCoinsSupported.items
    },
    createImage(file) {
      for (const f in file.target.files) {
        const reader = new FileReader()
        reader.onloadend = () => {
          this.formData.data.screenshoots.value.push({
            name: 'GL screen',
            icon: reader.result,
          })
        }
        reader.readAsDataURL(file.target.files[f])
      }
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--
      }
    },
    handleActiveStep(name) {
      this.currentStep = name
    },
  },
}
</script>

<style>
/* PP */
.entity-form {
  padding: 16px 24px;
}
.entity-form .pp-entity__table-wrap {
  margin-left: -24px;
  margin-right: -24px;
}

/* Stepper */
.entity-form__header-stepper {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: -16px;
  margin-right: -16px;
  padding-bottom: 16px;
  overflow-x: auto;
  user-select: none;
}
.entity-form__header-stepper::-webkit-scrollbar {
  height: 4px;
}
.entity-form__header-stepper-wrap {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.entity-form__header-stepper-wrap:after {
  content: "";
  width: calc(100% - 2px);
  height: 1px;
  background-color: var(--dark-grey-d-3);
  position: absolute;
  left: 1px;
  top: 50%;
  z-index: 1;
  transform: translate(0, -50%);
}
.entity-form__header-stepper-arrow {
  cursor: pointer;
}
.entity-form__header-stepper-arrow svg {
  display: block;
  min-width: 20px !important;
}
.entity-form__header-stepper-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  background-color: var(--white);
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.entity-form__header-stepper-item-mark {
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  position: relative;
}
.entity-form__header-stepper-item-mark:before,
.entity-form__header-stepper-item-mark:after {
  content: "";
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.entity-form__header-stepper-item-mark:before {
  width: 100%;
  height: 100%;
  border: 2px solid var(--dark-grey-d-3);
}
.entity-form__header-stepper-item-mark:after {
  width: 50%;
  height: 50%;
  background-color: var(--dark-grey-d-3);
}
.entity-form__header-stepper-item-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: var(--dark-grey-d-3);
  white-space: nowrap;
}
.entity-form__header-stepper-item--active .entity-form__header-stepper-item-mark:before {
  border: 2px solid var(--soft-blue);
}
.entity-form__header-stepper-item--active .entity-form__header-stepper-item-mark:after {
  background-color: var(--soft-blue);
}
.entity-form__header-stepper-item--active .entity-form__header-stepper-item-text {
  font-weight: 600;
  color: var(--space-cadet);
}


/* form */
.entity-form__main-form {
  display: flex;
}
.entity-form__main-form-left,
.entity-form__main-form-right {
  flex: 1 0 50%;
  padding-top: 24px;
}
.entity-form__main-form-left {
  padding-right: 24px;
  border-right: 1px solid var(--cotton-grey-f-5);
}
.entity-form__main-form-right {
  padding-left: 24px;
}
.entity-form__main-form .gl-input__input,
.entity-form__main-form .vs__dropdown-toggle {
  height: 32px !important;
  min-height: 32px !important;
  background-color: var(--cotton-grey-f-5);
  font-size: 12px;
}
.entity-form__main-form .tagged .vs__dropdown-toggle {
  height: auto !important;
}
.entity-form__main-form .gl-input__label,
.entity-form__main-form .gl-select__label {
  margin-bottom: 8px;
  font-weight: 400;
  text-transform: none;
}
.entity-form__main-form .v-select,
.entity-form__main-form .vs__search {
  font-size: 12px !important;
}
.entity-form__main-form .v-select .vs__selected .flex {
  gap: 8px;
}
.entity-form__main-form .v-select .vs__selected .flex .mr-1 {
  margin-right: 0 !important;
}

.entity-form__main-form-header {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.entity-form__main-form-block,
.entity-form__without-wrapper {
  margin-bottom: 16px;
}
.entity-form__main-form-block .mb-1,
.entity-form__without-wrapper .mb-1 {
  margin-bottom: 0 !important;
}
.entity-form__main-form-block--with-color {
  display: flex;
  gap: 16px;
}
.entity-form__main-form-block--with-color > .gl-input__wrapper {
  flex: 1 0 auto;
}
.entity-form__main-form-block--with-color > .gl-select__wrapper {
  flex: 0 0 100px;
}
.entity-form__main-form-block--wswg #quill-container {
  height: 86px;
}


.entity-form__main-form-block .vs__dropdown-menu {
  min-width: 100%;
}
.entity-form__main-form-block .color-item {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.entity-footer-wrapper {
  padding-top: 16px;
  border-top: 1px solid var(--cotton-grey-f-5);
}
.entity-form-reset-button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: var(--lipstick);
}
.entity-form-reset-button svg {
  display: block;
  min-width: 20px;
  margin-top: -2px;
}

@media (max-width: 1199px) {
  .entity-form__main-form {
    display: block;
  }
  .entity-form__main-form-left {
    padding-top: 16px;
    padding-right: 0;
    border-right: none;
  }
  .entity-form__main-form-right {
    padding-left: 0;
  }
}

@media (max-width: 1023px) {
  .entity-form__header-stepper {
    padding-left: 8px;
    padding-right: 8px;
  }
  .entity-form__header-stepper-item {
    padding-left: 8px;
    padding-right: 8px;
  }
  .entity-form__header-stepper-item-text {
    font-size: 12px;
  }
  .entity-form__header-stepper-item-mark {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
  }
}

@media (max-width: 767px) {
  .entity-form {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background-color: var(--white);
    border-radius: 12px;
  }
  .entity-form__main-form-block--with-color > .gl-select__wrapper {
    flex: 0 0 75px;
  }
}
</style>
